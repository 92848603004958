export const fetchVehicleAverageRunReportURL = (month) => {
  return `/vehicle/vehicle-avg-run?month=${month}`;
};

export const downloadAvgRunReportURL = (month) => {
  return `/vehicle/vehicle-avg-run?month=${month}&is_download=true`;
};

export const fetchVehicleSummaryURL = () => {
  return `/vehicle/vehicle-unavailable-record`;
};

export const downloadReportURL = (data) => {
  return `booking/report`;
};

export const downloadVehicleUtilizationURL = (data) => {
  return `booking/vehicle-utilization`;
};

export const fetchVehicleUtilizationURL = () => {
  return '/booking/vehicle-utilization';
};

export const downloadReportOptionsURL = (data) => {
  return `booking/downloadable-reports`;
};

export const fetchTotalTripsURL = (months) => {
  return `trip/customer-trip-report?month_count=${months}`;
};

export const fetchCustomerTotalTripsURL = () => {
  return `trip/customer-trip-report`;
};

export const deleteVehicleRecordURL = () => {
  return `vehicle/delete-vehicle-unavailable-record`;
};

export const fetchDailyCustomerReportURL = (type) => {
  const {typeFilter, extraParams} = type;
  console.log(typeFilter, extraParams);
  return `trip/daily-${extraParams}customer-report${typeFilter}`;
};

//.....filter_..............
export const fetchYearlyReportFilterURL = (type) => {
  const {typeFilter} = type;
  console.log(typeFilter);
  return `trip/customer-trip-report${typeFilter}`;
};
//.....filter_..............

export const fetchVendorWiseDistributionURL = () => {
  return `trip/vendor-wise-distribution`;
};

export const fetchZoneWiseDistributionURL = () => {
  return `trip/zone-wise-report`;
};
export const sendZastNowReportURL = () => {
  return '/finance/cost/zast-now-report';
};

export const sendPaymentReportURL = () => {
  return '/finance/payment-report';
};
export const fetchContractedVehicleURL = () => {
  return 'tracking/vehicle-report';
};
export const downloadContractedVehicleReportURL = () => {
  return '';
};
export const fetchContractedVehicleTableURL = () => {
  return 'tracking/vehicle-report-detail';
};

export const fetchDailyCustomerTripReportURL = () => {
  return `trip/daily-customer-trips`;
};
export const fetchDownloadCustomerTripReportURL = () => {
  return `trip/download-trip/daily_report`;
};
export const fetchCustomerReportURL = (activeTab) => {
  return `customer/report?type=${activeTab}`;
};
export const fetchVehicleStatusSummaryReportURL = (category) => {
  return `trip/vehiclesummaryreport?category=${category}`;
};
export const fetchVehicleStatusSummaryReportDetailURL = (filters) => {
  const {
    download = false,
    size = '',
    status = '',
    multipleStatus = '',
    zone = '',
    city = [],
    vendor = [],
    base_location = [],
    category = 'con',
  } = filters;
  const _dict_type = size?.replace('final_', '');
  return `trip/vehiclestatusreport?count_status_list=${multipleStatus}&status=${
    status === 'Total' ? 'Available' : status
  }&zone=${zone.length === 0 ? 'T' : zone}&city=${
    city.length > 0 ? city.map((item) => item.split(',')[0]) : city
  }&vendor=${
    vendor.length > 0 ? vendor.map((item) => item.split(',')[0]) : vendor
  }&base_location=${
    base_location.length > 0
      ? base_location.map((item) => item.split(',')[0])
      : base_location
  }&dict_type=${_dict_type}&download=${download}&category=${category}`;
};
export const fetchVendorSearchData = (data) => {
  console.log('finance/get-pending-fnf-trips-summary' + data);
  return 'finance/get-pending-fnf-trips-summary' + data;
};
