import {TRIP_DETAILS} from '../../../constants';

export const fetchAllTripsByStatusURL = (status) => {
  return `/trip/${status}`;
};

export const fetchTripCommentsURL = (trip_id) => {
  return `/trip/trip-comment/${trip_id}`;
};

export const checkTripBeginConditionsURL = (trip_id) => {
  return `/trip/check-begin-conditions/${trip_id}`;
};

export const clickToCallURL = () => {
  return `/driver/driver-click-to-call`;
};

export const checkTripArrivalConditionsURL = () => {
  return `/check-arrival-conditions`;
};

export const fetchTatDistanceURL = (tripId, route) => {
  return `/trip/get-tat-distance/${tripId}/${route}`;
};

export const markTripAsBeginURL = () => {
  return `/trip/begin-trip`;
};

export const markTripAsCloseURL = () => {
  return `/trip/close-trip`;
};

export const markTripAsArrivedURL = () => {
  return `/trip/arrived-destination`;
};

export const markTripAsTouchingInURL = () => {
  return `/arrived-intermediate`;
};

export const updateMultipleLocationsURL = () => {
  return `/trip/update-location`;
};

export const markTripAsHaltedURL = () => {
  return `/trip/halt-trip`;
};

export const fetchLastLocationURL = () => {
  return '/trip/check-location-for-time';
};

export const markTripAsTerminateURL = () => {
  return `/trip/terminate-trip`;
};

export const editTripVehicleURL = () => {
  return '/trip/edit-trip-vehicle';
};

export const editTripRouteURL = () => {
  return '/trip/edit-trip-route';
};

export const editTripCustomerURL = () => {
  return '/edit-trip-customer';
};

export const fetchTrackingHistoryURL = (trip_id, url) => {
  return `/v2/trip/tracking-history/${trip_id}`;
};

export const deleteTripHistoryCommentURL = () => {
  return '/delete-trip-history';
};

export const editTripHistoryCommentURL = () => {
  return '/edit-trip-history';
};

export const addTripCommentsURL = (trip_id) => {
  return `trip/trip-comment/${trip_id}`;
};

export const addTripRunningStatusURL = () => {
  return `/add-trip-running-status`;
};

export const updateDriverPhoneURL = () => {
  return `/edit-phone`;
};

export const updateReferenceURL = () => {
  return `/edit-reference`;
};

export const updateTripTATURL = () => {
  return `/edit-tat`;
};

export const plotRouteURL = () => {
  return `/plot-route`;
};

export const crossDockTripURL = () => {
  return `/crossdock`;
};

export const reverseGeocodeURL = (lat, long) => {
  return `/trip/reverse-geocode?lat=${lat}&long=${long}`;
};

export const fetchLocationsURL = (q, limit) => {
  return `/trip/map-search-results?search=${q}&limit=${limit}`;
};

export const addRunningStatus = (trip_id) => {
  return `/trip/running-status/${trip_id}`;
};
export const fetchAutofillDistance = () => {
  return '/autofill-tat-distance';
};

export const fetchTripDetailsMessage = (trip_id) => {
  return `/trip/trip-detail-message/${trip_id}`;
};
export const fetchTrackingLocationURL = (trip_id) => {
  return `/trip/coordinates/${trip_id}`;
};

export const fetchTripDtailsURL = (trip_id, url) => {
  return `${url}${trip_id}`;
};

export const deleteCommentsURL = (id) => {
  return `trip/trip-comment/${id}`;
};

export const checkDriverPhoneURL = (phone) => {
  return `/trip/check-driver-phone/${phone}`;
};

export const addLocationURL = () => {
  return `/trip/add-location-manual`;
};

export const checkArrivalConditionURL = (tripId) => {
  return `/trip/check-arrival-conditions/${tripId}`;
};

export const editDriverPhoneURL = () => {
  return `/trip/edit-trip-driver`;
};

export const arriveIntermediateURL = () => {
  return `/trip/arrive-intermediate`;
};

export const markAsTouchingOutURL = () => {
  return `/trip/begin-trip`;
};

export const downloadTripDetailsURL = (url) => {
  return `/trip/download-trip/${url}`;
};

export const tripReturnRouteURL = (tripId) => {
  return `/trip/trip-return-booking/${tripId}`;
};

export const tripSupportingDocsURL = (tripId) => {
  return `/trip/trip-supporting-docs/${tripId}`;
};

export const editTripVendor = (id) => {
  return `/trip/edit-trip-vendor/${id}`;
};

export const fetchDriverConsentURL = (tripID) => {
  return `/tracking/fetch-consent/${tripID}`;
};

export const beginWithoutConsentURL = (tripID) => {
  return `/tracking/dissent-reason/${tripID}`;
};

export const resendForDriverConsentURL = (tripID) => {
  return `/tracking/resend-consent/${tripID}`;
};

export const getDriverConsentURL = (tripID) => {
  return `/tracking/get-consent-details/${tripID}`;
};

export const fetchHaltDurationsURL = (tripId) => {
  return `/trip/halts-duration/${tripId}`;
};
export const downloadHaltDurationsURL = (tripId, currentDuration) => {
  return `/trip/halts-duration-download/${tripId}?currentDuration=${currentDuration}`;
};

export const fetchUnverifiedDryRunURL = (search) => {
  return `trip/unverified-dryrun${search ? search : ''}`;
};

export const fetchUnverifiedDryRunTripReportURL = () => {
  return '/trip/vehicle-trips-data';
};
// export const postUnverifiedDryRunURL = () => {
//   return `trip/unverified-dryrun`;
// };
export const editTripReferenceURL = (id) => {
  return `/trip/edit-trip-reference/${id}`;
};

export const updateEwayDataURL = (trip_id) => {
  return `/trip/get-eway-data/${trip_id}`;
};
