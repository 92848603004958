export const fetchTicketHistoryURL = (trip_id) => {
  return `/trip/get-tickets?trip_id=${trip_id}`;
};

export const fetchTicketSelectURL = () => {
  return `/trip/create-manual-ticket`;
};

export const changeTicketStatusURL = () => {
  return `/trip/change-ticket-status`;
};

export const updateTicketURL = () => {
  return `/trip/ticket-type-change`;
};

export const fetchClosedTicketsURL = (search) => {
  return `/trip/closed-tickets${search}`;
};

export const fetchOpenTicketsURL = (search) => {
  return `/trip/active-tickets${search}`;
};

export const fetchTicketDetailsDataURL = (ticket_id) => {
  return `/trip/get-history-ticket?ticket_id=${ticket_id}`;
};

export const updateEscalationLevelURL = () => {
  return `/trip/ticket-escalation-change`;
};

export const updateHaltStartTimeURL = () => {
  return `/trip/change-exception-start-time`;
};
