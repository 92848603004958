export const uploadFastTagURL = () => {
  return `/vehicle/fasttag-upload`;
};

export const uploadDieselURL = () => {
  return `/vehicle/diesel-upload`;
};

export const uploadFastTagManualURL = () => {
  return `/vehicle/fasttag-upload-manual`;
};

export const fetchApproveFastTagURL = (search) => {
  return `/vehicle/fasttag-approval?${search}`;
};

export const approveFastTagURL = (search) => {
  return `/vehicle/fasttag-approval?${search}`;
};

export const fetchApproveDieselURL = (search) => {
  return `/vehicle/diesel-approval?${search}`;
};

export const approveDieselURL = (search) => {
  return `/vehicle/diesel-approval?${search}`;
};

export const saveMaintenanceReportURL = () => {
  return `vehicle/vehicle-maintenance`;
};
export const getRepairMaintenanceReportURL = () => {
  return `vehicle/vehicle-maintenance`;
};

export const getHappayProcessingRequestURL = () => {
  return `onpay/happay-request-processing/`;
};

export const saveHappayProcessingRequestURL = () => {
  return `onpay/happay-request-processing/`;
};
