export const fetchTripPerformanceURL = () => {
  return '/booking/trip-performance';
};

export const downloadTripPerformanceURL = () => {
  return '/booking/performance-report';
};

export const fetchRoutesURL = () => {
  return '/booking/get-routes';
};
