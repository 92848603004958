export const fetchMarketRevenueDetailsURL = (search) => {
  return `/finance/market-revenue-missing?${search}`;
};

export const fetchCustomerDetailsByIdURL = () => {
  return `/customer/dialogue-details`;
};

export const fetchRevenueCustomerTripsURL = (
  customerId,
  {business_segment_list},
) => {
  if (!business_segment_list) {
    return `/finance/missing-revenue-trips/${customerId}`;
  } else {
    if (business_segment_list.length === 1) {
      return `/finance/missing-revenue-trips/${customerId}?business_segment_list=${business_segment_list[0]}`;
    }
    if (business_segment_list.length === 2) {
      return `/finance/missing-revenue-trips/${customerId}?business_segment_list=${business_segment_list[0]}&business_segment_list=${business_segment_list[1]}`;
    }
    if (business_segment_list.length === 3) {
      return `/finance/missing-revenue-trips/${customerId}?business_segment_list=${business_segment_list[0]}&business_segment_list=${business_segment_list[1]}&business_segment_list=${business_segment_list[2]}`;
    }
  }
};

export const saveRevenueCustomerTripURL = () => {
  return `/finance/add-market-revenue`;
};

export const fetchRevenueAddedTripsURL = (search) => {
  return `/finance/trips-revenue-added?${search}`;
};

export const saveRevenueAddedTripURL = () => {
  return `/finance/edit-market-revenue`;
};

export const revenueSubmitBillingURL = () => {
  return `/finance/send-for-billing`;
};

export const fetchRateMatrixCostURL = () => {
  return `/booking/cost`;
};

export const fetchRateMatrixRouteMarginURL = () => {
  return `/booking/routemargin`;
};

export const fetchRateMatrixReturnRouteURL = () => {
  return `/booking/returnrate`;
};

export const fetchRateMatrixForwardRouteURL = () => {
  return `/booking/forwardrouterate`;
};

export const uploadRouteMarginURL = () => {
  return `/booking/routemargin`;
};

export const uploadReturnRouteURL = () => {
  return `/booking/returnrate`;
};

export const uploadCostURL = () => {
  return `/booking/cost`;
};

//REVENUE BASE RATES
export const createRevenueBaseRateURL = () => {
  return `/finance/revenue-base-rate/`;
};

export const fetchRevenueBaseRatesURL = (page, search) => {
  return `/finance/revenue-base-rate/?page=${page}${search}`;
};

export const editRevenueBaseRateURL = (baseRateId) => {
  return `/finance/revenue-base-rate/${baseRateId}/`;
};

export const downloadBaseRateReportURL = () => {
  return `/finance/revenue-base-rate/download/`;
};

//revenue trips
export const downloadRevenueTripsReportURL = () => {
  return `/finance/trip-revenue/download/`;
};

export const searchRevenueTripsDataURL = () => {
  return `/finance/trip-revenue/initial/`;
};

export const getSearchRevenueTripsDataURL = (page) => {
  return `/finance/trip-revenue/search/`;
};

export const getSearchVendorTripsDataURL = (page) => {
  return `/finance/cost-trip/search/`;
};

export const editTripRevenueURL = () => {
  return `/finance/trip-revenue/`;
};

export const editTripCostURL = (id) => {
  return `/finance/cost-trip/${id}/`;
};

export const updateDieselRateURL = () => {
  return `/finance/trip-revenue/update_diesel_rate/`;
};

export const updateCostDieselRateURL = () => {
  return `finance/cost-trip/update_diesel_rate/`;
};

export const getBaseRateToApplyURL = (customer, route, tripIds) => {
  return `/finance/trip-revenue/get_base_rate/?customer_id=${customer}&route=${route}&trip_ids=${tripIds}`;
};

export const applyBaseRateURL = () => {
  return '/finance/trip-revenue/apply_base_rate/';
};

export const getDetentionDaysURL = (tripId, returnTripId) => {
  return `/finance/detention-days?forward_trip_id=${tripId}`;
};

export const getVendorDetentionDaysURL = (tripId, returnTripId) => {
  return `/finance/cost-detention-days?trip_id=${tripId}`;
};

export const postVendorDetentionDaysURL = (tripId, returnTripId) => {
  return `finance/cost-detention-days`;
};

export const editDetentionDaysURL = () => {
  return `/finance/detention-days`;
};

export const editTripRevenueReferenceURL = () => {
  return `/finance/edit-trip-refrence`;
};

export const createAnnexureURL = () => {
  return `/finance/annexure/`;
};

export const createCostAnnexureURL = () => {
  return `/finance/cost-trip/`;
};

export const viewAnnexureURL = () => {
  return `/finance/annexure/view_annexure/`;
};

export const viewCostAnnexureURL = () => {
  return `/finance/cost-trip/view_annexure/`;
};
