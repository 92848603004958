import axios from 'axios';
import {PATH} from '../../constants';
import {isEmpty} from 'lodash';
import qs from 'qs';
import {message} from 'antd';
import store from '../../store';
import {LOGOUT_SUCCESSFUL} from '../../modules/auth';

export const doRequest = async (requestData) => {
  const defaultHeader = {};

  // timeout specifies the number of milliseconds before the request times out.
  // If the request takes longer than timeout, the request will be aborted.
  //const timeout = 5000; // default is 0 (no timeout)

  // xsrfCookieName is the name of the cookie to use as a value for xsrf token
  const xsrfCookieName = 'XSRF-TOKEN'; // default

  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  const xsrfHeaderName = 'X-XSRF-TOKEN'; // default
  // const token = cookies.get('token');
  const auth = JSON.parse(localStorage.getItem('auth'));
  const token = auth ? auth.token : null;

  const {
    headers = {Authorization: token ? `Token ${token}` : null},
    method = 'get',
    url = '',
    baseURL = process.env.REACT_APP_API_HOST,
    params = {},
    data = {},
    onUploadProgress,
    cancelToken,
  } = requestData;
  console.log('params ----1', params);
  //create request config according to data
  const requestConfig = {
    headers: Object.assign(defaultHeader, headers),
    method,
    url,
    baseURL,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, {arrayFormat: 'comma'});
    },
    data,
    // xsrfCookieName,
    // xsrfHeaderName,
    onUploadProgress,
    cancelToken: cancelToken,
  };

  try {
    console.log('requestConfig ----', requestConfig);
    const response = await axios(requestConfig);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw error;
    }
    if (error?.response?.status === 401) {
      store.dispatch({type: LOGOUT_SUCCESSFUL});
    }
    // if (error.response.status === 500) {
    //   message.error('Service Error');
    // }
    const {response: {data = {}} = {}} = error || {};
    return isEmpty(data) ? {data, message: error.message} : data;
  }
};
