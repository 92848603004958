export const searchAnnexuresDataURL = (page) => {
  return `/finance/annexure/search/?page=${page}`;
};

export const approveAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/approve/`;
};

export const rejectAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/reject/`;
};

export const commentAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/comment/`;
};

export const othersHeaderEditURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/edit_others_name/`;
};

export const deleteAnnexuresURL = () => {
  return `/finance/annexure/delete/`;
};

export const sendForApprovalURL = () => {
  return `/finance/annexure/send_for_approval/`;
};

export const saveBillDetailsURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/save_bill_details/`;
};

export const annexureUploadBillURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/upload_bill/`;
};

export const createNextAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/create_next_annexure/`;
};

export const getAnnexuresDataURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/`;
};

export const editAnnexureTripRevenueURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/`;
};

export const getSearchAnnexuresDataURL = () => {
  return `/finance/annexure/initial/`;
};

export const editAnnexureDieselDateURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/edit_diesel_date/`;
};

export const deleteTripsAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/annexure_delete_trips/`;
};

export const printAnnexureURL = (annexureId) => {
  return `/finance/annexure/${annexureId}/?print=true`;
};

//SEND TO TALLY

export const searchApprovedAnnexuresDataURL = (page) => {
  return `/tally/tallyannexure/search/?page=${page}`;
};

export const generateBillOnTallyURL = () => {
  return `/tally/tallyannexure/annexures_tally_send/`;
};

export const resendToTallyURL = () => {
  return `/tally/resend-annexure`;
};
