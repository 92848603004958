export const postbulkuploadURL = () => {
  return `/finance/bulk-upload-pod`;
};

export const postbulkupdatestatusURL = () => {
  return `/finance/bulk-pod-status-update`;
};

export const getpodsummaryreportURL = (page, type, filter) => {
  return `/finance/pod-summary-report?type=${type}&page=${page}${filter}`;
};

export const getpoddetailreportURL = (page, filter) => {
  return `/finance/pod-detail-report?page=${page}${filter}`;
};
export const downloadPodDetailURL = (url) => {
  return `/finance/${url}`;
};
export const uploadDocumentURL = (id) => {
  return `/finance/upload-pod/${id}`;
};
export const getPodHistoryURL = (id) => {
  return `/finance/pod-history/${id}`;
};
export const bulkUploadPodDetailsURL = () => {
  return '/finance/bulk-upload-pod';
};
