export const pauseResumeTrackingLinkURL = () => {
  return `/trip/shareable-tracking-status`;
};

export const fetchTrackingDetailsURL = (isAll) => {
  // return `/tracking/track-all${isAll ? '?all=true' : ''}`;
  return `/tracking/track-all`;
};

export const fetchTrackingHistoryApiURL = (vehicleNumber, queryString) => {
  return `/tracking/track/${vehicleNumber}${queryString}`;
};

export const fetchTripCodeOptionsURL = (searchText) => {
  return `/tracking/trip-search?trip_code=${searchText}`;
};

export const getTripCodesReportURL = () => {
  return `/tracking/trip-distance`;
};

export const fetchMapViewVendorRecordsURL = (search = '') => {
  return `/vehicle/map-view-vendors?${search}`;
};

export const mapViewVehiclesURL = () => {
  return `/vehicle/map-view-vehicles`;
};

export const fetchFenceDetailsURL = () => {
  return `/tracking/geofence-location`;
};

export const addFenceDetailsURL = () => {
  return '/tracking/geofence-location';
};

export const updateFenceDetailsURL = (fenceId) => {
  return `/tracking/geofence-location/${fenceId}`;
};

export const fetchDriverTrackingDetailsURL = (search) => {
  return `/driver/driver_actions?${search}`;
};

export const fetchDriverUploadsDetailsURL = (page, bin) => {
  let binquery = '';
  if (bin) {
    binquery = bin;
  } else {
    bin = '';
  }
  return `/driver/driver_uploads?page=${page}${binquery}`;
};

export const editDriverUploadURL = (params) => {
  return `/driver/driver_uploads${params}`;
};

export const checkDriverLicenseURL = () => {
  return `/driver/check-driving-license`;
};
