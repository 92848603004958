import React, {Component} from 'react';
import {Provider} from 'react-redux';
import store from './store';
import Route from './routes';
import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || 'G-XJRDHNNXPE');
// import OneSignalApp from './OneSignal';

class App extends Component {
  constructor(props) {
    super(props);
    console.log('process.env ----', process.env);
  }

  render() {
    return (
      <Provider store={store}>
        {/*<OneSignalApp />*/}
        <Route />
      </Provider>
    );
  }
}

export default App;
