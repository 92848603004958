export const fetchDOInitialDataURL = () => {
  return `/transin/create-do`;
};

export const editDO = () => {
  return `/transin/create-do`;
};

export const fetchDoListURL = (search) => {
  return `/transin/all-do/?${search}`;
};

export const fetchDOByIdURL = (doId, search) => {
  let url = `/transin/get-do-data?do_id=${doId}`;
  if (search && search.length > 0) {
    url += `&trip_code=${search}`;
  }
  return url;
};

export const checkBillingEntityURL = (pan_number) => {
  return `/vendor/billing-entity?name=${pan_number}`;
};

export const deleteOrCloseDOURL = () => {
  return '/transin/do-action';
};

export const releaseLoadsURL = () => {
  return `/transin/release-load`;
};

export const fetchConsignorsByCustomerURL = (customerId) => {
  return `/transin/get-customer-consignor?customer_id=${customerId}`;
};

export const assignVehicleURL = () => {
  return 'transin/assign-vehicle';
};

export const recordShortageDataURL = () => {
  return 'transin/shortage';
};

export const deleteTripPODURL = () => {
  return 'finance/pod-delete';
};
