import {REQUEST_TYPE} from '../../../constants';
import {doRequest} from '../../../helpers/network';

const INITIAL_STATE = {
  fetchingDropDownData: false,
};

const FETCH_DROP_DOWN_DATA = 'FETCH_DROP_DOWN_DATA';
const FETCH_DROP_DOWN_DATA__COMPLETED = 'FETCH_DROP_DOWN_DATA__COMPLETED';
const FETCH_BILL_NUMBER = 'FETCH_BILL_NUMBER';
const FETCH_TRIP_ADVANCE_FAILED = 'FETCH_TRIP_ADVANCE_FAILED';

export const fetchDropDownData = (name) => {
  if (name) {
    name = '?name=' + name;
  }
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DROP_DOWN_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/finance/ad-hoc-advance/' + name,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_DROP_DOWN_DATA__COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const findBillNumber = (reqdata) => {
  return async (dispatch) => {
    let response;
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: reqdata,
      });

      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_BILL_NUMBER, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const sendSubmitData = (data1) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DROP_DOWN_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: '/finance/ad-hoc-advance',
        data: data1,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_DROP_DOWN_DATA__COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_DROP_DOWN_DATA:
      return {
        ...state,
        fetchingDropDownData: true,
      };
    case FETCH_DROP_DOWN_DATA__COMPLETED:
      return {
        ...state,
        mydata: payload,
        fetchingDropDownData: false,
      };
    case FETCH_TRIP_ADVANCE_FAILED:
      return {
        ...state,
        fetchingDropDownData: false,
      };
    case FETCH_BILL_NUMBER:
      return {
        ...state,
        myBillNumber: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
