import axios from 'axios';

let obj = {
  cancelPreviousRequestFetchDoList: null,
  cancelPreviousRequestFetchDOById: null,
};

const UseAbortApiCall = (value) => {
  let cancelToken;
  const cancelApiCall = () => {
    if (obj[value]) {
      obj[value]();
    }
    cancelToken = axios.CancelToken.source();
    obj[value] = cancelToken.cancel;
    return cancelToken.token;
  };

  return {
    cancelApiCall,
  };
};
export default UseAbortApiCall;
