export const signInURL = () => {
  return '/user/login';
};

export const signOutURL = () => {
  return '/user/logout';
};

export const fetchUserDetailsURL = () => {
  return '/user/get-user-detail';
};

export const fetchInitialDataURL = () => {
  return '/trip/initial-data';
};

export const resendOtpURL = () => {
  return '/user/resend-otp';
};

export const savePasswordURL = () => {
  return `/user/verify-reset-password`;
};

export const generateOtpURL = () => {
  return '/user/send-otp';
};

export const verifyOtpURL = () => {
  return `/user/verify-reset-otp`;
};

export const fetchChangePasswordURL = () => {
  return `/internal/user/change-password`;
};

export const fetchAlertMailsURL = () => {
  return `/internal/email-preferences`;
};

export const subscribeAlertMailURL = () => {
  return 'internal/email-preferences/subscribe-unsubscribe-email';
};

//admin panel

export const fetchRolesURL = () => {
  return `/internal/alert-mail-count`;
};

export const fetchAlertMailsByRoleURL = (roleId) => {
  return `/internal/role-alert-mail/${roleId}`;
};

export const updateRoleAlertMailURL = () => {
  return `/internal/role-alert-mail/`;
};
