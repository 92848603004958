export const fetchVendorEditURL = () => {
  return '/update-vendor';
};

export const fetchVehicleEditURL = () => {
  return '/update-vehicle';
};

export const fetchVendorByIdURL = (vendor_id) => {
  return `/vendor/${vendor_id}`;
};

export const verifyVendorURL = () => {
  return '/vendor/verify-otp';
};

export const sendOTPURL = () => {
  return '/vendor/generate-otp';
};

export const fetchVendorDetailsURL = (page, search) => {
  return `/vendor/all?page=${page}${search}`;
};

export const fetchVendorFinanceDetailsURl = (page, search) => {
  return `/vendor/details?page=${page}${search}`;
};

export const addVendorDetailsURL = (vendorId) => {
  return `/vendor/add-details/${vendorId}`;
};

export const addVendorURL = () => {
  return '/vendor/add';
};

export const editVendorURL = (vendorId) => {
  return `/vendor/edit/${vendorId}`;
};

export const editVendorDetailsURL = (vendorId) => {
  return `/vendor/edit-details/${vendorId}`;
};

export const getSuggestionURL = () => {
  return '/vendor/get-suggestion';
};

export const getNickNameSuggestionURL = () => {
  return '/vendor/nickname-suggestion';
};

export const approveVendorURL = () => {
  return '/vendor/approve-details';
};

export const generateNewOtpURL = () => {
  return `/vendor/generate-otp`;
};

export const checkIFSCCodeURL = (code) => {
  return `/vendor/check-ifsc/${code}`;
};

export const fetchVehicleDriversURL = (vehicleId) => {
  return `/vehicle/${vehicleId}/fetch-driver`;
};

export const fetchVendorInitialDataURL = () => {
  return `/customer/customer-initial-data`;
};

export const fetchVendorInitialDataCSCCountryURL = (filter) => {
  const {country} = filter;
  return `/general/state-city?country=${country}`;
};
export const fetchVendorInitialDataCSCStateURL = (filter) => {
  const {state} = filter;
  return `/general/state-city?state=${state}`;
};
