export const fetchDriverRecords = (page, search = '') => {
  return `/finance/fnf-summary?page=${page}${search}`;
};

export const downloadFnFReportURL = () => {
  return `/driver/download-fnf`;
};

export const fetchDriverTripsURL = (driverId) => {
  return `/finance/fnf-detail/${driverId}`;
};

export const fetchFnFDataURL = (tripId) => {
  return `/finance/fnf-request/${tripId}`;
};

export const approveFnFURL = (tripId) => {
  return `/finance/fnf-request/${tripId}`;
};

export const sendForApprovalURL = (driverId) => {
  return `/finance/fnf-detail/${driverId}`;
};
