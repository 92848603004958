export const getSearchDataURL = (page) => {
  return `/trip/search?page=${page ? page : 1}`;
};

export const editDateTimeURL = () => {
  return `/trip/edit-trip-history`;
};

export const editTripTypeChangeURL = () => {
  return `/trip/trip-type-change`;
};

export const getReasonDropDownData = '/booking/edit-tat-reasons';
export const postTatData = '/booking/edit-tat';
