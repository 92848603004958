export const fetchBulkUploadTabsURL = () => {
  return `/general/bulk_upload`;
};

export const fetchLatestUploadsURL = () => {
  return `/general/latest_upload`;
};

export const bulkUploadDocumentsURL = () => {
  return `/general/bulk_upload`;
};
