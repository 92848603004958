export const fetchLorryReceiptDataURL = (tripId) => {
  return `/trip/lorry-reciept/${tripId}`;
};

// export const fetchLorryReceiptByIdURL = (tripId, lrId) => {
//   return `/trip/lorry-reciept/${tripId}?lr_id=${lrId}`;
// };

export const fetchLorryReceiptByIdURL = (tripId, lrId) => {
  let url = `/trip/lorry-reciept/${tripId}`;
  if (
    lrId !== undefined &&
    lrId !== 'undefined' &&
    lrId !== null &&
    lrId !== ''
  ) {
    url += `?lr_id=${lrId}`;
  }
  return url;
};

export const fetchAllLRURL = (tripId) => {
  return `/trip/all-lorry-reciept/${tripId}`;
};

export const printLorryReceiptURL = (tripId, lrId) => {
  return `/trip/print-lr/${tripId}/${lrId}`;
};

export const deleteLorryReceiptURL = (tripId) => {
  return `/trip/lorry-reciept/${tripId}`;
};

//for Digital forms
export const createDigitalLorryReceiptURL = () => {
  return `/trip/lr-creation`;
};
