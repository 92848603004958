export const searchCostAnnexuresDataURL = (page) => {
  return `/finance/cost-annexure/search/?page=${page}`;
};

export const getCostAnnexuresDataURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/`;
};

export const approveCostAnnexureURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/approve/`;
};

export const rejectCostAnnexureURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/reject/`;
};

export const costOthersHeaderEditURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/edit_others_name/`;
};

export const deleteCostAnnexuresURL = () => {
  return `/finance/cost-annexure/delete/`;
};

export const sendForApprovalCostURL = () => {
  return `/finance/cost-annexure/send_for_approval/`;
};

export const saveCostBillDetailsURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/save_bill_details/`;
};

export const costAnnexureUploadBillURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/upload_bill/`;
};

export const editCostAnnexureTripRevenueURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/`;
};

export const deleteCostTripsAnnexureURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/annexure_delete_trips/`;
};

export const printCostAnnexureURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/?print=true`;
};

export const createCostNextAnnexureURL = (annexureId) => {
  return `/finance/cost-annexure/${annexureId}/create_next_annexure/`;
};

//RAISE DISPUTE
export const createDisputeCostAnnexureURL = () => {
  return `/finance/partner-dispute/`;
};

export const getPartnerDisputeURL = (annexureId) => {
  return `/finance/partner-dispute/${annexureId}/`;
};

//VENDOR BASE RATES
export const createVendorBaseRateURL = () => {
  return `/finance/vendor-base-rate/`;
};

export const fetchVendorBaseRatesURL = (page, search) => {
  return `/finance/vendor-base-rate/?page=${page}${search}`;
};

export const fetchVendorBaseRateInitialURL = () => {
  return `/finance/vendor-base-rate/initial_data/`;
};

export const fetchVendorBaseRateByIdURL = (baseRateId) => {
  return `/finance/vendor-base-rate/${baseRateId}/`;
};

export const editVendorBaseRateURL = (baseRateId) => {
  return `/finance/vendor-base-rate/${baseRateId}/`;
};

export const downloadVendorBaseRateReportURL = () => {
  return `/finance/vendor-base-rate/download/`;
};

export const searchOwnBillingAnnexuresDataURL = (page) => {
  return `/finance/zast-now-cost-annexure?page=${page}`;
};

export const viewOrPrintOwnBillingAnnexureURL = (id) => {
  return `finance/zast-now-cost-annexure?annexure_id=${id}`;
};
