export const markAttendanceURL = () => {
  return '/driver/update-attendance';
};

export const fetchAttendanceByIdURL = (driverId, date) => {
  return `/driver/view-attendance?driver_id=${driverId}&date=${date}`;
};

export const fetchAllZastDriverListURL = (search) => {
  return `/driver/zast-driver?${search}`;
};
export const fetchDownloadMarkAttendanceURL = (params) => {
  if (params) {
    return `/driver/driver-attendance-report?${params}`;
  } else {
    return `/driver/driver-attendance-report`;
  }
};
